import React from 'react';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

export const captionProgressStart = .51;
export const captionProgressFull = .54;
export const captionProgressFade = .85;
export const captionProgressGone = 1;

const ParallaxCaption = (props: {
  textContent: React.ReactNode | string
  progress: number
}) => {
  const {
    textContent,
    progress,
  } = props;
  const isSmallViewport = useMediaQuery('(max-width: 620px) or (max-height: 600px)');
  const bottomPercentStart = isSmallViewport ? 0 : 16;

  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (containerRef.current) {
      const opacity = progress < captionProgressStart
        ? 0
        : progress < captionProgressFull
          ? (progress - captionProgressStart) / (captionProgressFull - captionProgressStart)
          : progress < captionProgressFade
            ? 1
            : progress < captionProgressGone
              ? 1 - ((progress - captionProgressFade) / (captionProgressGone - captionProgressFade))
              : 0;
      containerRef.current.style.setProperty('--opacity', opacity.toString());

      const bottomPercentInitial = bottomPercentStart;
      const bottomPercentInitialDirectedDistance = bottomPercentStart - bottomPercentInitial;
      const bottomPercentEnd = -2;
      const bottomPercentDirectedDistance = bottomPercentEnd - bottomPercentStart;

      const bottomPercent = progress < captionProgressFull
        ? bottomPercentInitial + (((progress - captionProgressStart) / (captionProgressFull - captionProgressStart)) * bottomPercentInitialDirectedDistance)
        : bottomPercentStart + ((progress - captionProgressFull) / (captionProgressGone - captionProgressFull) * bottomPercentDirectedDistance);
      containerRef.current.style.setProperty('--bottom', `${bottomPercent}%`);
    }
  }, [progress, bottomPercentStart]);
  return (
    <>
      {(
        <div
          ref={containerRef}
          style={{
            position: 'absolute',
            bottom: `var(--bottom, ${bottomPercentStart}%)`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 'var(--opacity, 0)',
          }}
          className="imageCaption"
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              position: 'relative',
              alignItems: 'center',
              textAlign: 'center',
              padding: '1.2dvw',
              justifyItems: 'center',
              fontFamily: 'Acumin',
              fontSize: '1em',
              overflow: 'auto',
              color: 'black',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, .9)',
                padding: '15px',
                border: '1px solid black',
                width: '100%',
              }}
            >
              {textContent}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ParallaxCaption;
