import React from 'react';
import ParallaxItem from './ParallaxItem';
import { captionProgressFull } from './ParallaxCaption';

export const imageProgressGone = .85;

const ParallaxImage = (
  props: {
    src: string
    scale?: number
    textContent?: React.ReactNode | string
  }
) => {
  const imageRef = React.useRef<HTMLImageElement>(null);
  const handleProgressChangedThrottled = React.useCallback((progress: number) => {
      if (imageRef.current) {
          const opacity = progress > captionProgressFull
            ? 1 - Math.min(1, ((progress - captionProgressFull) / (imageProgressGone - captionProgressFull))) : 1;
          imageRef.current.style.setProperty('--opacity', opacity.toString());
      }
  }, []);

  return (
    <ParallaxItem
      onProgressChangeThrottled={handleProgressChangedThrottled}
      textContent={props.textContent}
    >
      <img
        ref={imageRef}
        src={props.src}
        style={{
          width: `${(props.scale ?? 1) * 100}%`,
          height: `${(props.scale ?? 1) * 100}%`,
          ...props.scale !== undefined && {
            top: `${(1 - props.scale) / 2 * 100}%`,
          },
          objectFit: 'contain',
          position: 'absolute',
          opacity: 'var(--opacity, 1)',
        }}
      />
    </ParallaxItem>
  );
}

export default ParallaxImage
