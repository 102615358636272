import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import ParallaxCaption from './ParallaxCaption';

const ParallaxItem = (
  props: {
    children?: React.ReactNode | string
    render?: (args: { progress: number, speed: number }) => React.ReactNode | string
    onEnter?: () => void
    onExit?: () => void
    onProgressChange?: (progress: number) => void
    onProgressChangeThrottled?: (progress: number) => void
    textContent?: React.ReactNode | string
  }
)=> {
  const [progress, setProgress] = React.useState(0);
  const [speed, setSpeed] = React.useState(0);
  const {
    children,
    render,
    onEnter,
    onExit,
    onProgressChange,
    onProgressChangeThrottled,
    textContent,
  } = props;

  const handleProgressChangeThrottled = useThrottledCallback(
    (progress: number) => {
      setProgress(progress);
      setSpeed((.5 - progress) * 30);
      onProgressChangeThrottled?.(progress);
    },
    [onProgressChangeThrottled],
    0,
  );

  const handleProgressChange = React.useCallback((progress: number) => {
    onProgressChange?.(progress);
    handleProgressChangeThrottled(progress);
  }, [onProgressChange, handleProgressChangeThrottled]);

  React.useLayoutEffect(() => {
    onProgressChangeThrottled?.(progress);
  }, [progress, onProgressChangeThrottled])

  return (
    <Parallax
      {...{
        ...onEnter && {
          onEnter,
        },
        ...onExit && {
          onExit,
        }
      }}
      scale={[.5, 1.5]}
      rootMargin={{ left: 0, right: 0, top: 0, bottom: 0 }}
      onProgressChange={handleProgressChange}
      style={{
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
      speed={speed}
    >
      {render?.({ progress, speed })}
      {children}
      {textContent && (
        <ParallaxCaption
          textContent={textContent}
          progress={progress}
        />
      )}
    </Parallax>
  );
}

export default ParallaxItem
