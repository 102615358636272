import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import {
  BiVolumeMute,
  BiVolumeFull,
  BiPlay,
  BiPause,
} from 'react-icons/bi'
import ParallaxItem from './ParallaxItem';
import { captionProgressFull } from './ParallaxCaption';
import { imageProgressGone } from './ParallaxImage';

function secToVimeoTimeFormat(sec: number) {
  let remainingSec = sec
  let result = ''
  if (remainingSec >= 60) {
    const minutes = Math.floor(remainingSec / 60)
    result += `${minutes}m`
    remainingSec -= (minutes * 60)
  }
  if (remainingSec) {
    result += `${remainingSec}s`
  }
  return result
}

type VideoPlatform = 'youtube' | 'vimeo'

function getVideoUrl({ videoId, videoPlatform }: { videoId: string, videoPlatform: VideoPlatform }) {
  switch (videoPlatform) {
    case 'youtube':
      return `https://www.youtube-nocookie.com/embed/${videoId}`
    case 'vimeo':
      return `https://vimeo.com/${videoId}`
  }
  throw new Error()
}

const ParallaxVideo = (props: {
  videoId?: string
  videoPlatform?: VideoPlatform
  videoUrl?: string
  videoStart?: number
  textContent?: React.ReactNode | string
  beginMuted?: boolean
}) => {
  const {
    videoId,
    videoPlatform,
    videoUrl,
    videoStart,
    textContent,
    beginMuted,
  } = props
  const playerRef = useRef<ReactPlayer | null>(null)
  const [muted, setMuted] = useState(beginMuted ?? false)
  const [videoReady, setVideoReady] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [widthPerHeight, setWidthPerHeight] = useState(16 / 9)

  const containerRef = React.useRef<HTMLDivElement>(null);
  const handleProgressChangedThrottled = React.useCallback((progress: number) => {
      if (containerRef.current) {
          const opacity = progress > captionProgressFull
            ? 1 - Math.min(1, ((progress - captionProgressFull) / (imageProgressGone - captionProgressFull))) : 1;
          containerRef.current.style.setProperty('--opacity', opacity.toString());
      }
  }, []);

  const handleProgress = React.useCallback(({ played, loaded, loadedSeconds, playedSeconds }: {
    played: number
    loaded: number
    loadedSeconds: number
    playedSeconds: number
  }) => {
    if (true as boolean) {
      return;
    }
    if (playedSeconds > 0) {
      if (containerRef.current) {
        const iframe: HTMLIFrameElement | null = containerRef.current.querySelector('iframe');
        if (iframe) {
          if (false as boolean) {
            console.log(`${iframe.width} x ${iframe.height}`);
          }
          if (false as boolean) {
            // not allowed
            const video: HTMLVideoElement | null | undefined = iframe.contentWindow?.document.querySelector('video');
            if (video) {
              setWidthPerHeight(video.videoWidth / video.videoHeight);
            }
          }
        }
      }
    }
  }, []);

  const VolumeIcon = muted
    ? BiVolumeMute
    : BiVolumeFull

  const PlayIcon = playing
    ? BiPause
    : BiPlay

  return (
    <ParallaxItem
      onEnter={() => setPlaying(true)}
      onExit={() => {
        setTimeout(() => {
          setPlaying(false);
          if (false as boolean) {
            setMuted(true);
          }
        }, 200);
      }}
      onProgressChangeThrottled={handleProgressChangedThrottled}
      textContent={textContent}
    >
      <div
        ref={containerRef}
        onClick={() => setPlaying(value => !value)}
        className="player-wrapper"
        style={{
          visibility: videoReady ? 'visible' : 'hidden',
          opacity: 'var(--opacity, 1)',
          ...(false as boolean) && {
            paddingTop: `${(1 / widthPerHeight) * 100}%`,
          },
        }}
      >
        <ReactPlayer
          className="react-player"
          ref={playerRef}
          url={videoUrl ?? (videoId && videoPlatform && getVideoUrl({ videoId, videoPlatform }))}
          playing={playing}
          loop
          muted={muted}
          volume={1}
          width="100%"
          height="100%"
          onProgress={handleProgress}
          playsinline
          config={{
            youtube: {
              playerVars: {
                /**
                  * NOTE: this property seems to be no longer fully supported
                  */
                rel: 0,
                /**
                  * Disable keyboard input
                  */
                disablekb: 1,
                playsinline: 1,
                ...videoId && {
                  playlist: videoId,
                },
                /**
                  * Hide player controls
                  */
                controls: 0,
                /**
                  * Hide fullscreen button
                  */
                fs: 0,
                loop: 1,
                /**
                  * NOTE: this property seems to be no longer supported
                  */
                //vq: 'hd1080',
                /**
                  * NOTE: this property seems to be no longer supported
                  */
                //hd: 1,
              },
            },
            vimeo: {
              playerOptions: {
                dnt: 1,
                keyboard: 0,
                playsinline: 1,
                autopause: 0,
                autopip: 0,
                pip: 0,
                background: 1,
              },
            },
          }}
          onReady={() => {
            playerRef.current?.seekTo(videoStart ?? 0)
            setVideoReady(true)
          }}
          onEnded={() => playerRef.current?.seekTo(videoStart ?? 0)}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: 'rgba(0, 0, 0, .25)',
          padding: '7px',
          borderRadius: '10px',
          cursor: 'pointer',
        }}
        onClick={() => setMuted(value => !value)}
      >
        <VolumeIcon
          style={{
            color: 'white',
            fontSize: '1.5em',
            position: 'relative',
            top: '1.5px',
            userSelect: 'none',
          }}
        />
      </div>
      <div
        onClick={() => setPlaying(value => !value)}
        style={{
          position: 'absolute',
          //top: '10px',
          //right: '10px',
          bottom: '1em',
          right: '1em',
          backgroundColor: 'rgba(0, 0, 0, .25)',
          padding: '7px',
          borderRadius: '10px',
          cursor: 'pointer',
        }}
      >
        <PlayIcon
          style={{
            color: 'white',
            fontSize: '1.5em',
            position: 'relative',
            top: '1.5px',
            userSelect: 'none',
          }}
        />
      </div>
    </ParallaxItem>
  )
}

export default ParallaxVideo
