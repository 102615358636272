import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import './App.css';
import ParallaxImage from './components/ParallaxImage';
import ParallaxItem from './components/ParallaxItem';
import ParallaxVideo from './components/ParallaxVideo';

type MenuItemName = 'branding' | 'campaigns' | 'digital' | 'fineArt' | 'ai';

function MenuButton(props: { text: string, selected?: boolean, onClick?: () => void }) {
  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <div
      style={{
        display: 'flex',
        textDecoration: (isHovering && (true && !props.selected)) ? 'underline' : 'none',
        textTransform: 'uppercase',
        fontFamily: props.selected ? 'BigJohn' : 'SlimJoe',
        fontWeight: props.selected ? 400 : 600,
        cursor: props.selected ? 'default' : 'pointer',
        userSelect: 'none',
        overflow: 'hidden',
      }}
      className="menuDiv"
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      onClick={() => props.onClick?.()}
    >
      {props.text}
    </div>
  );
}

function App() {
  const [scrollEl, setScrollElement] = React.useState<HTMLDivElement|undefined>();
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<MenuItemName|undefined>('branding');
  const ref = React.useRef<HTMLDivElement|null>(null);
  React.useEffect(() => {
    setScrollElement(ref.current ?? undefined);
  });

  const handleMenuItemClick = React.useCallback((menuItemName: MenuItemName) => {
    setSelectedMenuItem(menuItemName);
  }, []);

  React.useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = 0;
    }
  }, [selectedMenuItem, scrollEl]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100dvw',
        height: '100dvh',
      }}
    >
      <header
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexShrink: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
          }}
          className="menuContainerDiv"
        >
          <MenuButton
            text="Branding/"
            selected={selectedMenuItem === 'branding'}
            onClick={() => handleMenuItemClick('branding')}
          />
          <MenuButton
            text="Campaigns/"
            selected={selectedMenuItem === 'campaigns'}
            onClick={() => handleMenuItemClick('campaigns')}
          />
          <MenuButton
            text="Digital/"
            selected={selectedMenuItem === 'digital'}
            onClick={() => handleMenuItemClick('digital')}
          />
          <MenuButton
            text="Fine Art/"
            selected={selectedMenuItem === 'fineArt'}
            onClick={() => handleMenuItemClick('fineArt')}
          />
          <MenuButton
            text="A.I."
            selected={selectedMenuItem === 'ai'}
            onClick={() => handleMenuItemClick('ai')}
          />
        </div>
      </header>
      <main
        style={{
          width: '100%',
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          position: 'relative',
        }}
        ref={ref}
      >
        <ParallaxProvider scrollContainer={scrollEl}>
          {selectedMenuItem === 'branding' && (
            <>
              <ParallaxImage
                src="images/Branding/A&WBurger.png"
                textContent={
                  <>
                    For A&W, I recommended updated food photography with an emphasis on professional studio quality
                    and high resolution. This was quite a change from the in-restaurant photography they had been
                    using. As an Art Director and Graphic Designer, working with A&W was an opportunity
                    to offer a touch of discernment to this great brand.
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/A&WChickenSandwich.png"
                textContent={
                  <>
                    When a classic American brand like A&W faces flagging sales, brand re-imaging must extend beyond the
                    the basics of logo, color scheme, and photography; product merchandising and brand voice become the 
                    focal points for progress, as in the
                    case of the humble chicken sandwich. With art direction in mind, I recommended studio photography
                     with a dark background to add sophistication and allure.
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/Jaguar.png"
                textContent={
                  <>
                    When Jaguar introduced the XF, they were ready for a revolution in their demographic sector. No longer
                    satisfied with appealing to legacy, Jaguar needed to embrace a younger demographic by focusing messaging
                    on sleek aesthetics and high power motoring. As an Art Director in an agency setting, I helped Jaguar toe
                    the line between their two core demographics - men 35-55 and men 55 - 75. For the updated photography styling,
                    I drew inspiration from cinematic masterpieces like Mission Impossible, to create a thrilling vibe that appeals
                    to men of every age.
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/LJS Salmon Combo.png"
                textContent={
                  <>
                    As an Art Director, it's important to help clients see the potential their own brand has yet to unlock.
                    Major restaurant chains like Long John Silver's often suffer from a sort of tunnel-vision that drives
                    their franchises forward but may come at the cost of innovation. Fortunately, Long John Silver's embraced my
                    graphic and branding recommendations and moved forward with a more upscale brand identity.
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/LJS.png"
                textContent={
                  <>
                    In this example, we see how the simple introduction of blue wooden picnic boards
                    was enough to suggest Nantucket Luxury, where before we saw only "Discount Seafood." My art direction has carried
                    forward in ways that continue to inform and shape this brand.
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/LJSCoconutShrimp.png"
                textContent={
                  <>
                    I'm particularly proud of the way my art direction had the cross functional impact of influencing
                    product development in the case of Long John Silver's Coconut Shrimp offering and other menu items. My art
                    direction created a "wave" in the corporate offices that spurred development in many departments. The use of
                    illustrative details like palm trees created a nautical americana theme that complimented the new logo and
                    rang true with customers -- bringing a marked 12% increase in sales. (Note the minor use of yellow.)
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/LJScombo.png"
                textContent={
                  <>
                    Oftentimes, brand identity is as much about what you remove as what you add. For Long John Silver's, I
                    drastically de-emphasized yellow from the color pallete, using it only as a special accent, instead of a constant crutch.
                    (As, according to color theory, yellow can be too alarming for certain use cases.) This "return to the sea" of blue brought
                    cooler vibes to Long John Silver's and softened the brand's identity in a sophisticated way.
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/RobindaleEnergy.png"
                  textContent={
                  <>
                    These days, I feel guilty about my role in helping further the interests of a coal mining company, but advertising
                    can be an indelicate business for those of us who care about C02 emissions. To their credit, Robindale
                    Energy Services is committed to restoring the land and water for wildlife preservation in areas where they mine.
                    Despite the C02 emissions caused by burning coal, I was proud, at the time, to create a logo that illustrated
                    their stated desire to restore the Earth. This logo contains in its hexagons the chemical structure of coal and offers
                    a bespoke professionalism not found in their former namebrand and logo. This name change was prescribed to lend a more 
                    human-centered appeal.
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/SRULogo.png"
                textContent={
                  <>
                    Commensurate with the advent of their major infrastructural and curricular improvements, Slippery Rock University
                    was ready to graduate from their Rock-n-Roll inspired logo and focus their brand on more erudite, academic ideals. The use of
                    a clean, corporate sans serif font brought a new level of credibility and trustworthiness to this state-college-turned-university,
                    which appealed to students, parents, professors, and donors alike, while remaining true to its heart and soul.

                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/SRUSignage.png"
                textContent={
                  <>
                    New signage across campus completed Slippery Rock University's new aeshetic.

                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/Westmoreland.png"
                textContent={
                  <>
                    Westmoreland Transit wanted to update their brand to reflect the needs of their growing geographic
                    and demographic reach. I came up with an iconic name brand and logo to
                    emphasize the accessibility, reliability, and speed of Westmoreland's many transit offerings.

                  </>
                }
              />
              <ParallaxImage
                src="images/WestmorelandGoTransit.png"
                textContent={
                  <>
                    This logo and rebranding revitalized a trusted transit brand without losing its essence.

                  </>
                }
                />
              <ParallaxImage
                src="images/Branding/SolutionReachLogo.png"
                textContent={
                  <>
                    Ahead of a major company sale, I rebranded this multi-million dollar company from
                     a dental-focused standalone product to a more generalized healthcare brand that 
                     would serve OB/GYNs, Ophthalmologists, Optometrists, and
                    Physicians of every kind! A name change and logo update were the order of the day
                    and led to a highly lucrative ompany sale.

                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/Branding - BSD.png"
                textContent={
                  <>
                    When I joined Beyond Spots & Dots, they were approaching a huge phase of growth and were ready to bring
                    their logo into a new decade. 
                    After some testing and revisions, their logo was refreshed to reflect the modern 'flat design' aesthetic,
                    while being a hardly noticible advancement for a well-established company.

                  </>
                }
              />
              
            </>
          )}
          {selectedMenuItem === 'campaigns' && (
            <>
              {false && (
                <ParallaxVideo
                  videoId="__bfAnh0zfw"
                  videoPlatform="youtube"
                  beginMuted
                  textContent={
                    <>
                      Test 4:3 aspect ratio
                    </>
                  }
                />
              )}
              <ParallaxVideo
                videoId="hkUuYYm-_D0"
                videoPlatform="youtube"
                textContent={
                  <>
                    Carl W. Herrmann wanted to normalize women buying furs for themselves, so I helped
                    develop a campaign featuring models who were cast with an eye toward appealing to a 
                    female audience (whereas before, Carl Herrmann had been casting and styling models 
                    with a focus on appealing to men). An increase in female shoppers ensued!
                  </>
                }
              />
              <ParallaxVideo
                videoId="j1iRW1fji-s"
                videoPlatform="youtube"
                beginMuted
                textContent={
                  <>
                    For this campaign, I assisted in the areas of graphics, strategy, and content development,
                     helping NexTier Bank establish a classic, credible, and corporate-friendly voice that
                     appealed to local community members and FDIC auditors who were considering this bank 
                     for FDIC membership, which was soon thereafter finalized.
                  </>
                }
              />
              <ParallaxVideo
                videoId="j6nMMfcIev8"
                videoPlatform="youtube"
                beginMuted
                textContent={
                  <>
                    For this campaign I offered art direction, including a rebrand to focus on 
                    stripes as a central thematic element of the bank. For this ad, I also consulted on strategy and script, 
                    with the goal of appealing to homeowners with young families, resulting in a 10% increase in home equity loan
                    applications among existing members. 
                  
                  </>
                }
              />
              <ParallaxImage
                src="images/Campaigns/Nextierbankbeforeandafter.png"
                textContent={
                  <>
                    For this campaign centered around FDIC approval, I recommended that NexTier Bank de-emphasize
                     the lime green in their brand, and opt for a white logo on a blue field, with white or blue stripes.
                      The stripes are meant to represent the tiers of financial progress through which NexTier is helping
                      their customers advance. 
                    
                  </>
                }
              />
              <ParallaxImage
                src="images/Campaigns/Nextierbanksignage.png"
                textContent={
                  <>
                    Pleased with this campaign, NexTier bank changed the signage at their new locations to incorporate
                    this brand identity focused on clean, clear, professional messsaging with stripes of white and blue.
                    This had the effect of helping the FDIC auditors consider Nextier Bank as a potential nationally 
                    recognizable brand, while establishing trustworthiness with consumers.
                 
                  </>
                }
              />
              <ParallaxImage
                src="images/Campaigns/Latter-Day Cafe Logo + Photography.png"
                textContent={
                  <>
                    Here's a sample of my art direction at work in photography and brand identity.
                    In addition to designing the logo, and cup design -- I also arranged this photoshoot
                    to highlight the beauty of Latter-Day Cafe's Pink Drink.
                    It was well received in print and on social media, causing this experimental new menu 
                    item to become a standard customer favorite and a top seller.
                  </>
                }
              />
              <ParallaxImage
                src="images/Lobster Mobile Hero Framework LobBites Platter LJS 2018.png"
                textContent={
                  <>
                    This multi-platform campaign for Long John Silver's included digital advertisements,
                    print advertisements, online coupons, email marketing, and in-store posters for restaurants.
                    It was a really enlightening experience to help Long John Silver's move their brand in a
                    more modern direction across multiple platforms.
                  </>
                }
              />
              
              <ParallaxImage
                src="images/s-l1600.jpg"
                textContent={
                  <>
                    This campaign for Jaguar's XF spanned digital and print media to great effect.
                   
                  </>
                }
              />
              
              <ParallaxImage
                src="images/Campaigns/Jaguar Hero 2012 Alive Campaign.jpeg"
                textContent={
                  <>
                    For this campaign, I collaborated with corporate in-house copywriters and photographers to 
                    establish a new brand constant for photographic styling
                    and product positioning while unveiling the new XF.
                   
                  </>
                }
              />
              
              <ParallaxImage
                src="images/Campaigns/Pittsburgh Wine Festival 2018 - Original Illustration - Poster.png"
                textContent={
                  <>
                    Here's an illustrated poster I made while serving as the Art Director for the Pittsburgh Wine Festival
                    under the leadership of JP Russell IV. This hand drawn illustration lent a personal feeling 
                    to the brand that increased ticket sales dramatically and resonated with Pittsburgh residents in a fun way.
                    This poster was approved by the Pennsylvania Fine Wine & Good Spirits State Liquor Board, who are notoriously
                    strict on alcohol-related advertising guidelines.
                   
                  </>
                }
              />
            </>
          )}
          {selectedMenuItem === 'digital' && (
            <>
            <ParallaxImage
                src="images/Campaigns/BetterDentalCase1.png"
                textContent={
                  <>
                    <b>A Successful Digital Campaign</b> For three years in a row, I helped Better Dental attain Best Dentist in Wake 
                    County with a successful online campaign. (The custom embroidered scrubs I recommended also helped establish brand credibility
                    and were a big hit on Instagram.)
                  </>
                }
              />
               <ParallaxImage
                src="images/Campaigns/BetterDentalCase2.png"
                textContent={
                  <>
                    <b>A Successful Digital Campaign</b> I helped Better Dental attain the title of Best Dentist in Wake County with a successful campaign spanning
                    email, Facebook, Instagram, and OOH advertising.
                  </>
                }
              />
             <ParallaxImage
                src="images/Digital/Better Dental BEST.jpeg"
                textContent={
                  <>
                    <b>A Successful Digital Campaign</b> My campaign resulted in a great ROI on EDDM mailers, SEM and SEO.
                  </>
                }
              />
             <ParallaxImage
                src="images/Digital/Better Dental collage.png"
                textContent={
                  <>
                    <b>Facebook and Instagram Growth</b> Consistent posting with AI-enhanced scheduling helped create a meaningful brand presence for this dental start up.
                  </>
                }
              />
             <ParallaxImage
                src="images/Digital/Better Dental facebook.png"
                textContent={
                  <>
                    <b>Social Media Management</b> Digital strategy for Better Dental extended beyond SEO and SEM, and also included full-scale social media management to great success. This reputation management also helped drive Google reviews.
                  </>
                }
              />
             <ParallaxImage
                src="images/Digital/Better Dental website.png"
                textContent={
                  <>
                    <b>Website Development</b> Website development included logo design, product development, brand strategy, customer testimonials, and interfacing with appointment booking APIs.
                  </>
                }
              />
             <ParallaxImage
                src="images/Digital/GoWestmorelandFacebook.png"
                textContent={
                  <>
                    <b>Social Media Engagement</b> No brand identity geared toward a 40+ demographic is complete without a Facebook page. I helped Westmoreland Transit create and manage a Facebook page for their new brand.
                  </>
                }
              />
             <ParallaxImage
                src="images/Digital/Jaguar Monroeville.png"
                textContent={
                  <>
                    <b>Web Design & Development </b> Jaguar USA needed help creating websites for local dealerships, for which I offered mockups and web design consultation.
                  </>
                }
              />
             <ParallaxImage
                src="images/Digital/Peppers homepage.png"
                textContent={
                  <>
                    <b>Online Fashion Sales </b> Sunglasses brand Peppers USA came to my agency for a new website, including art direction,
                    graphic design, copywriting, and development. A huge increase in online sales ensued.
                  </>
                }
              />
             <ParallaxImage
                src="images/Digital/Peppers photos page.png"
                textContent={
                  <>
                    <b>Digital Lifestyle Branding </b> Creating a meaningful brand identity for these sunglasses included a lot of 
                    focus groups, market research, and competitive research. We finally settled on a lifestyle-focused brand outlook to 
                    appeal to upwardly mobile young professionals with a special interest in water sports. This specialization created
                    an instant connection to consumers within the target demographic niche due to the product's buoyant frame design.  

                  </>
                }
              />
             <ParallaxImage
                src="images/Campaigns/Wexford Case Study.png"
                textContent={
                  <>
                    <b>Google Review Drive </b> I oversaw an impactful campaign for Wexford Chiropractic that included
                    a rebrand, social media updates, and a Google review drive using t-shirts to incentivize reviewers. 
                    
                  </>
                }
              />
               <ParallaxImage
                src="images/Campaigns/WexfordChiropractic.png"
                textContent={
                  <>
                    <b>Branding Makes SEM and SEO Campaigns Possible </b> Before we could begin advertising this well-loved
                      chiropractic clinic, we needed to change the fact that Wexford Chiropractic Centre had been
                      serving the community for decades without a proper logo. Once we had that in place, we could enhance 
                      their social media presence on TikTok, Facebook, and Instagram, and most importantly: Google Reviews. 
                      The business attracted major investors soon after my redesign of their website and social media presence.
                    
                  </>
                }
              />
            


            </>
          )}
          {selectedMenuItem === 'fineArt' && (
            <>
             <ParallaxImage
                src="images/Fine Art/A black cat in Five Strokes.jpg"
                textContent={
                  <>
                    <b>A Black Cat in Five Brushstrokes</b>, 2019. <em> Oil on a nine inch by twelve inch canvas.</em> In private collection, Pittsburgh, Penn.
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/A Martian View of Earth.png"
                textContent={
                  <>
                    <b>A Martian View of Earth</b>, 2022. <em> 24 X 36 Acrylic on Cardboard.</em> The Momentary, Bentonville, Ark.
                  </>
                }
              />
               
              <ParallaxImage
                src="images/Fine Art/Rude Dog and One Dweeb.png"
                textContent={
                  <>
                    <b>Rude Dog and One Dweeb</b>, 2018. <em> Cellophane on College-Ruled Notebook Paper.</em> Privately collected, Pittsburgh, Penn.
                  </>
                }
              />
              
              <ParallaxImage
                src="images/Fine Art/A Martian View of The Sun.png"
                textContent={
                  <>
                    <b>A Martian View of The Sun in Eight Brushstrokes</b>, 2018. <em> Oil on Nine Inch by Twelve Inch  Canvas.</em> Privately Collected, Pittsburgh, Penn.
                  </>
                }
              />
               
              <ParallaxImage
                src="images/Fine Art/TheExquisiteKetchupfly.png"
                textContent={
                  <>
                    <b>The Exquisite Ketchupfly</b>, 2015. <em> Marker on Copy Paper.</em> Privately Gifted, Salt Lake City.
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/Kona Sunset 24 X 36.png"
                textContent={
                  <>
                    <b>The Kona Sunset by Timothy Aller</b>, 2024. <em> Oil on Canvas, 24 X 36.</em> Privately Collected, Captain Cook, Hawaii. (Yes, I painted this.)
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/A Proclamation For their Actual Freedom.png"
                textContent={
                  <>
                    <b>A Proclamation For Their Actual Freedom</b>, 2018. <em> Pen & Ink.</em> Privately Collected, Pittsburgh, Penn.
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/Desert Sunset.png"
                textContent={
                  <>
                    <b>The Desert Sunset</b>, 2025. <em> Digital Painting, 3840 X 2744 pixels.</em> Unsold NFT
                  </>
                }
              />
               
              <ParallaxImage
                src="images/Fine Art/A Cactus in Five Strokes.png"
              
                textContent={
                  <>
                    <b>A Cactus in Five Brushstrokes</b>, 2022. <em> Acrylic on Fourteen by Fourteen Inch Canvas.</em> Privately Collected, Nashville, Tenn.
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/A Terrestrial View of The Space Needle.png"
                textContent={
                  <>
                    <b>A Terrestrial View of The Space Needle At Night</b>, 2019. <em> Oil on a nine inch by tweleve inch canvas.</em> Privately Collected, Pittsburgh, Penn.
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/Obedience.png"
                textContent={
                  <>
                    <b>OBEDIENCE</b>, 2021. <em> Pen & Ink.</em>
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/Heinz.png"
                textContent={
                  <>
                    <b>Heinz Ketchup</b>, 2017. <em> Digital Painting.</em> This work was inspired by Andy Warhol.
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/Oshun African Fertility Goddess.png"
                textContent={
                  <>
                    <b>Discarded Offering to Fertility Goddess, Oshun</b>, 2018. <em> Oil on 6 X 11 inch Paper Towel.</em>
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/SHARK6Blue.jpg"
                textContent={
                  <>
                    <b>Shark Six Blue, All Things Approach The Sun</b>, 2018. <em> Digital Print.</em>
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/Art.png"
                textContent={
                  <>
                    <b>"Art"</b>, 2015. <em> Digital Illustration.</em>
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/Liberty, Or The Bill of Rights.png"
                textContent={
                  <>
                    <b>Liberty, Or The Bill of Rights</b>, 2024. <em> Oil on 24 inch by 36 inch canvas.</em> Privately Collected, Captain Cook, Hawaii.
                    This work was inspired by Mark Rothko.
                  </>
                }
              />
              <ParallaxImage
                src="images/Fine Art/Three Potted Plants.png"
                textContent={
                  <>
                    <b>Three Potted Plants</b>, 2021. <em> Tempura On Artboard, 11 x 17. </em> 
                     Privately Collected, Nashville, Tenn. This work was done while holding a squirming toddler.
                  </>
                }
              />

            </>
          )}
          {selectedMenuItem === 'ai' && (
            <>
              <ParallaxImage
                src="images/AI/TealiumBeast.png"
                textContent={
                  <>
                    I felt inspired to use AI to enhance this adorable mascot for Tealium, known as the Tealium Beast. As usual, I used
                    photo editing software to enhance, edit and composite elements generated by AI. This represents
                    a blend of human and AI artistry.                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/TacoriKey.png"
                textContent={
                  <>
                     This ad was meant 
                    to help sell right hand diamond rings, which is a growing market for upscale jewelers, and we found great success.
                    My goal was to sell the idea of owning multiple Tacori rings without detracting from the core of Tacori's business which is the singular engagement ring.                
                    While AI is a powerful tool, it does not negate the necessity of the human touch in every major phase of 
                    a brand's campaign strategy, for instance, in strategizing and executing this multiple ring strategy. I did 
                    use AI to enhance this image for this campaign, but my best AI-assisted achievements
                    cannot flouish without talented photographers, models, copywriters and product stylists.
                  </>
                }
              />
              <ParallaxImage
                src="images/Branding/TacoriChess.png"
                textContent={
                  <>
                    As a Graphic Designer, I was using artificial intelligence before most. Generative image composition
                    allows photographers, art directors, and graphic designers to work together in unprecedented new ways. In
                    order to help Tacori be seen as more than just engagement rings, I art directed an ad featuring three rings, 
                    suggestive to the mind of collecting multiple Tacori rings - a notion the corporate office appreciated and which helped
                    make Tacori rings seem like an attainable and desirable item to collect in numbers, while still 
                    reminding bridal customers of Tacori's elegance, beauty, and variety.

                  </>
                }
              />
              <ParallaxImage
                src="images/AI/Pittsburgh Wine and Spirits Festival 2022 Web Ad.png"
                textContent={
                  <>
                    This simple, AI generated colorful background allowed me to assemble this campaign without
                    studio art, leading to a quicker turnaround time, and no need to sacrifice quality. The vibrant water colors
                    and craft paper texture create an artisanal feeling of care, reminsicent of the vinter's art.

                  </>
                }
            />
            </>
            
          )}
          <ParallaxItem
            render={({ progress }) => (
              <div style={{
                fontSize: '1.5em',
                fontFamily: 'SlimJoe',
                textAlign: 'center',
              }}>
                <p>Secretina Ruth Draper</p>
                <p><a href="https://upwork.com/freelancers/~01d30c553602682660" target="_blank" rel="noreferrer">Upwork Profile</a></p>
              </div>
            )}
          >
          </ParallaxItem>
        </ParallaxProvider>
      </main>
    </div>
  );
}

export default App;
